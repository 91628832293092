<template>
  <div>
    <v-card>
      <Header
        :headerTitle="headerTitle"
        :hasMenu="hasMenu"
        :menuList="menuList"
      ></Header>
      <v-skeleton-loader v-if="loading" type="article"></v-skeleton-loader>
      <div v-else>
        <v-card-text v-if="!userAbleToStartJobs">
          <notice-alert
            data-cy="insufficient_creds_alert"
            :body="terms.INSUFFICIENT_CREDS_BODY"
            :title="terms.INSUFFICIENT_CREDS_TITLE"
          />
        </v-card-text>
        <v-card-text v-else>
          <!-- select workflows -->
          <v-card outlined class="mb-1">
            <v-card-title class="text subtitle-1">Workflows</v-card-title>
            <v-card-text>
              <v-select
                data-cy="workflow_select"
                outlined
                dense
                label="Select Workflow"
                :items="getFormattedLabels(currentWorkflows)"
                item-text="formattedLabel"
                return-object
                v-model="selectedWorkflow"
                @input="prepareForm"
              >
              </v-select>
              <v-sheet
                v-if="'description' in selectedWorkflow"
                class="text-body-2 text"
                dense
                ><v-icon> mdi-information-outline </v-icon>
                {{ selectedWorkflow.description }}
              </v-sheet>
            </v-card-text>
          </v-card>

          <v-form
            ref="form"
            v-model="valid"
            v-if="'inputs' in selectedWorkflow"
          >
            <!-- Label and description input card -->
            <v-card outlined class="mb-1">
              <v-card-title class="text subtitle-1">
                Label and Description
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-text-field
                    data-cy="job_label"
                    class="mx-4"
                    label="Label"
                    v-model="label"
                    :rules="[rules.required]"
                    @input="provideFormInfo"
                  ></v-text-field>
                  <v-text-field
                    data-cy="job_description"
                    class="mx-4"
                    label="Description"
                    v-model="description"
                    :rules="[rules.required]"
                    @input="provideFormInfo"
                  ></v-text-field>
                </v-row>
              </v-card-text>
            </v-card>
            <!-- Select structures card -->
            <v-card outlined class="mb-1">
              <v-card-title class="text subtitle-1">
                Input Structures
              </v-card-title>
              <v-card-text>
                <v-row
                  v-for="(schema, index) in structureSchemas"
                  :key="schema._uuid"
                >
                  <v-select
                    :data-cy="schema.label + '_select'"
                    class="mt-5 ml-3"
                    outlined
                    dense
                    :label="schema.label"
                    :items="
                      datas.filter((e) => e.hqschema.label === schema.label)
                    "
                    item-text="label"
                    item-value="_uuid"
                    v-model="selectedStructures[index]"
                    return-object
                    :rules="[rules.required]"
                    @input="provideFormInfo"
                  >
                  </v-select>
                  <v-btn
                    fab
                    depressed
                    outlined
                    x-small
                    color="hqsOrange"
                    class="mt-6 mx-3"
                    :to="{ name: schema.label }"
                    @click="setStructureCard(schema.label)"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </v-row>
              </v-card-text>
            </v-card>
            <!-- Select or create job parameters card -->
            <v-card outlined class="mb-1" v-if="paramSchemas.length > 0">
              <v-card-title class="text subtitle-1">
                Job Parameters
              </v-card-title>

              <v-row>
                <v-select
                  data-cy="job_param_select"
                  class="mt-5 ml-7"
                  outlined
                  dense
                  :label="formatLabel(paramSchemaLabel)"
                  :items="getFormattedLabels(jobParamDatas)"
                  item-text="formattedLabel"
                  item-value="_uuid"
                  v-model="selectedParam"
                  :rules="[rules.required]"
                  return-object
                  @input="
                    selectedParam
                      ? prepareParamForm(true)
                      : (showParamForm = false)
                  "
                >
                </v-select>
                <!-- add job-params button -->
                <v-btn
                  data-cy="add_job_param"
                  fab
                  depressed
                  outlined
                  x-small
                  color="hqsOrange"
                  class="mt-6 ml-3 mr-3"
                  @click="prepareParamForm(false)"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
                <!-- delete job params -->
                <v-menu>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      fab
                      depressed
                      outlined
                      x-small
                      color="hqsOrange"
                      class="mt-6 mr-7"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                  <v-card width="400" class="scroll" max-height="500">
                    <v-list class="pl-5">
                      <v-list-item
                        link
                        v-for="(item, i) in jobParamDatas"
                        :key="i"
                      >
                        <v-list-item-content>
                          <v-list-item-title
                            class="darkGrey--text"
                            v-text="item.label"
                          >
                          </v-list-item-title>
                        </v-list-item-content>

                        <v-list-item-action>
                          <v-btn icon @click.stop="askConfirmation(item)">
                            <v-icon color="grey lighten-1">mdi-delete</v-icon>
                          </v-btn>
                        </v-list-item-action>
                      </v-list-item>
                    </v-list>
                  </v-card>
                </v-menu>
              </v-row>

              <div v-if="showParamForm">
                <FormField
                  class="mx-5"
                  v-for="(node, index) in nodes"
                  :key="index"
                  v-bind:valuePath="[node.name]"
                  v-bind:data="node"
                >
                </FormField>
              </div>
            </v-card>
            <notice-alert
              data-cy="pricing_alert"
              :body="`The pricing of running a job is dependent on the time the job needs
              for calculation.<br/>Price for one hour of computing time: ${creditsPerHour} HQ$`"
              icon="mdi-cash-multiple"
            />
          </v-form>
          <!-- additional validation information  -->
          <v-row v-if="showFormInfo">
            <v-col cols="7" />
            <v-col cols="5">
              <v-sheet
                class="text font-weight-light error--text"
                v-for="(element, index) in emptyFormElements"
                :key="index"
                >{{ element }}</v-sheet
              >
            </v-col>
          </v-row>
        </v-card-text>
        <SuccessAlert
          data-cy="alert"
          v-if="showAlert"
          :type="alertType"
          :message="alertMessage"
        />
        <v-card-actions v-if="'inputs' in selectedWorkflow">
          <!-- actions -->
          <v-spacer></v-spacer>
          <v-btn rounded text color="darkGrey" @click="clear">clear</v-btn>
          <!-- alternative submit button for extra validation -->
          <v-btn
            v-if="!valid"
            class="mr-2 mb-2"
            data-cy="validate"
            rounded
            dark
            depressed
            color="lightGrey"
            @mouseover="triggerFormInfo"
            >submit</v-btn
          >
          <v-btn
            v-else
            :disabled="!valid"
            class="mr-2 mb-2"
            data-cy="submit"
            rounded
            :dark="valid"
            depressed
            color="success"
            :loading="submitting"
            @click="submit"
            >submit</v-btn
          >
        </v-card-actions>
      </div>
    </v-card>
    <ConfirmDialog
      :visible="showConfirmDialog"
      @close="showConfirmDialog = false"
      @remove="deleteDataItems"
    />
  </div>
</template>

<script>
const ConfirmDialog = () => import('../general/ConfirmDialog.vue')
const FormField = () => import('../dynamicForms/FormField.vue')
const Header = () => import('../general/Header.vue')
const SuccessAlert = () => import('../general/SuccessAlert.vue')
const NoticeAlert = () => import('../general/NoticeAlert.vue')

import utils from '../../appUtils/utils'

import { mapMutations, mapState, mapActions, mapGetters } from 'vuex'
import { cloneDeep, isEqual, groupBy } from 'lodash'

import {
  MIN_CREDITS_FOR_JOB_START,
  TERMS,
  ALLOW_CREDITS_OVERUSE,
} from '../../data/constants'

export default {
  components: {
    FormField,
    Header,
    SuccessAlert,
    ConfirmDialog,
    NoticeAlert,
  },

  data() {
    return {
      /* Header */
      headerTitle: `Create New Job`,
      hasMenu: false,
      menuList: [],
      /* form data */
      label: '',
      description: '',
      selectedStructures: [],
      selectedParam: null,
      selectedWorkflow: {},
      paramSchemaLabel: '',
      /* additional validation info */
      emptyFormElements: [],
      showFormInfo: false,
      /* form validation */
      valid: true,
      rules: { required: (value) => !!value || 'Required' },
      /* data for preparing the form and creating the process node */
      inputDicts: [],
      currentWorkflow: {},
      paramSchemas: [],
      paramSchema: {},
      structureSchemas: [],
      savedParam: {},
      initialJobParamUserInput: {},
      hasJobParams: true,
      jobParamSchema: {},
      /* SuccessAlert */
      showAlert: false,
      alertMessage: '',
      alertType: '',
      /* UI mechanics */
      showParamForm: false,
      hideSelect: false,
      jobParamValue: {},
      inputSchemaLabels: [],
      /* controll spinners and skeleton loaders */
      loading: false,
      submitting: false,
      /* delete items */
      itemToDelete: {},
      showConfirmDialog: false,
      /* request error handling */
      schemasAlreadyReloaded: false,
    }
  },

  methods: {
    ...mapMutations('dynamicForms', [
      'normalizeSchema',
      'wipeForm',
      'createUserInputFromSchema',
      'createUserInputFromDataNode',
      'setStructureCard',
      'createStructureCards',
    ]),

    ...mapActions('backend', ['loadById', 'load', 'create', 'delete']),

    ...mapActions('tokens', ['loadPermissionsAndGroups']),

    async prepareForm() {
      try {
        this.clear()
      } catch (error) {
        console.log(error)
      }
      await this.createInputDicts(this.selectedWorkflow)
      await this.prepareInputSchemas()
      this.provideFormInfo()
    },

    triggerFormInfo() {
      this.showFormInfo = true
      this.provideFormInfo()
      this.$refs.form.validate()
    },

    provideFormInfo() {
      let emptyFormElements = []
      let groupedStructureSchemas = groupBy(this.structureSchemas, 'label')
      if (!this.valid) {
        if (this.label === '' || this.label === null) {
          emptyFormElements.push('Label is missing!')
        }
        if (this.description === '' || this.description === null) {
          emptyFormElements.push('Description is missing!')
        }
        if (
          this.selectedStructures.length <
          Object.keys(groupedStructureSchemas).length
        ) {
          emptyFormElements.push('At least one Input Structure is missing!')
        }
        if (this.selectedParam === null) {
          emptyFormElements.push('Job Parameters are missing!')
        }
        if (!this.valid && emptyFormElements.length === 0) {
          emptyFormElements.push(
            'Please fill out every Job Parameter form field!'
          )
        }
      }
      this.emptyFormElements = emptyFormElements
    },

    createInputDicts(workflow) {
      let inputDicts = []
      for (let key of Object.keys(workflow.inputs)) {
        if (
          typeof workflow.inputs[key] === 'object' &&
          !Array.isArray(workflow.inputs[key])
        ) {
          inputDicts.unshift({
            key: key,
            label: this.selectedWorkflow.inputs[key].hqschema.label,
            version: this.selectedWorkflow.inputs[key].hqschema.version,
          })
        }
        for (let dict of inputDicts) {
          if (
            this.getSchemasByTag('job-params').find(
              (e) => e.label === dict.label
            )
          ) {
            dict.type = 'job-params'
          } else {
            dict.type = 'structure'
          }
        }
      }
      this.inputDicts = inputDicts
    },

    prepareInputSchemas() {
      let structureSchemas = []
      let paramSchemas = []
      for (let item of this.inputDicts) {
        paramSchemas.push(
          ...this.getSchemasByTag('job-params').filter(
            (e) => e.label === item.label
          )
        )
        structureSchemas.push(
          ...this.getSchemasByTag('structure').filter(
            (e) => e.label === item.label
          )
        )
      }
      this.loadJobParamSchemas(paramSchemas)
      this.structureSchemas = structureSchemas
    },

    async loadJobParamSchemas(paramSchemas) {
      let fullParamSchemas = []
      try {
        for (let schema of paramSchemas) {
          fullParamSchemas.push(await this.loadById(['schema', schema._uuid]))
          this.schemasAlreadyReloaded = false
        }
        this.paramSchemas = fullParamSchemas
        this.paramSchemaLabel = paramSchemas[0].label
      } catch (error) {
        if (error.request.status === 404 && !this.schemasAlreadyReloaded) {
          await this.load(['schema'])
          this.schemasAlreadyReloaded = true
          this.loadJobParamSchemas(paramSchemas)
        }
      }
    },

    prepareParamForm(hasSelected) {
      let maxVersion = Math.max(
        ...cloneDeep(this.paramSchemas).map((e) => +e.version)
      )
      let schemaWithMaxVersion = this.paramSchemas.find(
        (e) => e.version === maxVersion.toString()
      )
      this.normalizeSchema([schemaWithMaxVersion, false])
      if (hasSelected) {
        this.provideFormInfo()
        this.createUserInputFromDataNode(this.selectedParam)
        this.initialJobParamUserInput = cloneDeep(this.userInput)
        this.paramSchema = schemaWithMaxVersion
      } else {
        this.createUserInputFromSchema()
        this.selectedParam = {}
        this.paramSchema = this.paramSchemas.find(
          (e) => e.version === schemaWithMaxVersion.version
        )
      }
      this.$refs.form.resetValidation()
      this.showParamForm = true
    },

    clear() {
      this.$refs.form.reset()
      this.$refs.form.resetValidation()
      this.selectedStructures = []
      this.createUserInputFromSchema()
      this.showFormInfo = false
    },

    askConfirmation(item) {
      this.itemToDelete = item
      this.showConfirmDialog = true
    },

    async deleteDataItems() {
      try {
        this.showConfirmDialog = false
        await this.delete(['data', this.itemToDelete._uuid])
        await this.load(['data'])
        this.alertType = 'success'
        this.alertMessage =
          'The job parameters ' +
          this.itemToDelete.label +
          ' were deleted successfully!'
        this.showAlert = true
      } catch (error) {
        this.showConfirmDialog = false
        this.alertType = 'error'
        this.alertMessage = 'Your job parameters could not be deleted.'
        this.showAlert = true
      }
    },

    async createJobParam() {
      try {
        if (isEqual(this.userInput, this.initialJobParamUserInput)) {
          this.savedParam = this.selectedParam
        } else {
          let param = this.getDataNode
          let savedParam = await this.create(['data', param])
          if (Array.isArray(savedParam)) {
            this.savedParam = savedParam.data[0]
          } else {
            this.savedParam = savedParam.data
          }
          await this.load(['data'])
        }
      } catch (error) {
        console.log('error: ', error)
        this.prepareAlert('error', Infinity, error.message)
      }
    },

    getProcessNode() {
      let processNode = {
        hqschema: {
          label: 'process_schema',
          version: '1.0',
        },
        label: this.label,
        description: this.description,
        inputs: {},
        workflow: {
          label: this.selectedWorkflow.label,
          version: this.selectedWorkflow.version,
        },
      }
      for (let input of this.inputDicts) {
        if (input.type === 'job-params') {
          processNode.inputs[input.key] = this.savedParam._uuid
        } else {
          processNode.inputs[input.key] = this.selectedStructures[0]._uuid
          this.selectedStructures.shift()
        }
      }
      return processNode
    },

    async submit() {
      this.showFormInfo = false
      this.emptyFormElements = []
      this.submitting = true
      this.showAlert = false
      let config = {}
      await this.createJobParam()
      config = this.getProcessNode()
      try {
        await this.create(['process', config])
        this.showParamForm = false
        await this.load(['process'])
        this.prepareAlert('success', 'Job created successfully')
        this.$emit('loadTable')
        this.wipeForm()
        this.$refs.form.reset()
      } catch (error) {
        this.prepareAlert('error', Infinity, error.response.data.message)
      }
      this.submitting = false
    },

    prepareAlert(type, message, validationMessage = '') {
      if (type === 'error') {
        this.alertMessage =
          message + ' ' + JSON.stringify(validationMessage, null, 4)
      } else {
        this.alertMessage = message
      }
      this.alertType = type
      this.showAlert = true
      if (type === 'success') {
        window.setTimeout(() => {
          this.showAlert = false
        }, this.alertTimeout)
      }
    },

    formatLabel(input) {
      return utils.formatLabel(input)
    },

    getFormattedSchemaLabels(schema) {
      return this.datas
        .filter((e) => e.hqschema.label === schema.label)
        .map((item) => ({
          ...item,
          formattedLabel: utils.formatLabel(item.label),
        }))
    },

    getFormattedLabels(input) {
      return input.map((item) => ({
        ...item,
        formattedLabel: utils.formatLabel(item.label),
      }))
    },
  },

  computed: {
    ...mapState('dynamicForms', {
      normalizedSchema: (state) => state.normalizedSchema,
      currentTopic: (state) => state.currentTopic,
      currentStructureCard: (state) => state.currentStructureCard,
      userInput: (state) => state.userInput,
    }),

    ...mapState('backend', {
      schemas: (state) => state.schemas,
      datas: (state) => state.datas,
      processes: (state) => state.processes,
      workflows: (state) => state.workflows,
      currentWorkflows: (state) => state.currentWorkflows,
      creditsPerHour: (state) => state.creditsPerHour,
      currentTopic: (state) => state.currentTopic,
      alertTimeout: (state) => state.alertTimeout,
    }),

    ...mapState('credits', ['credits']),

    ...mapState('tokens', ['userPermissions', 'userGroups']),

    ...mapGetters('dynamicForms', ['getDataNode']),

    ...mapGetters('backend', ['getSchemasByTag', 'getJobTable']),

    styleSelect() {
      return this.hideSelect ? 'mt-5 ml-7 hide-select' : 'mt-5 ml-7'
    },

    jobParamDatas() {
      return this.datas.filter(
        (e) => e.hqschema.label === this.paramSchemas[0].label
      )
    },

    nodes() {
      return this.normalizedSchema.nodes
    },

    terms() {
      return TERMS
    },

    minCreditsForJobStart() {
      return MIN_CREDITS_FOR_JOB_START
    },

    userAbleToStartJobs() {
      const isUserAllowedToOveruseCredits = this.userPermissions.includes(
        ALLOW_CREDITS_OVERUSE
      )

      if (!isUserAllowedToOveruseCredits) {
        return this.credits > this.minCreditsForJobStart
      }

      return true
    },
  },

  watch: {
    valid() {
      if (this.valid) {
        this.emptyFormElements = []
      }
    },
  },

  async created() {
    this.loading = true
    await this.load(['data'])
    this.wipeForm()
    await this.loadPermissionsAndGroups()
    if (this.schemas.length === 0) {
      await this.load(['schema'])
    }
    if (this.workflows.length === 0) {
      await this.load(['workflow'])
    }
    if (this.processes.length === 0) {
      await this.load(['process'])
    }
    this.createStructureCards(this.getSchemasByTag('structure'))
    this.loading = false
  },
}
</script>

<style lang="scss" scoped>
#param-select-btn {
  text-transform: unset !important;
}

.hide-select {
  visibility: hidden !important;
}
</style>
